<template>
  <div>


    <div class="container">

          <div class="header" style="">
      <h1>Aradığınız sayfa bulunamadı!</h1>
    </div>


      <div class="center">
        <p>
          <img src="@/assets/img/404.svg" />
        </p>



      </div>

    <a class="logo" href="/"><img  src="@/assets/img/iys-logo.svg" /></a>

    <a class="text" href="/">Anasayfa'ya ulaşmak için tıklayınız.</a>

    </div>


  </div>
</template>

<script>
export default {
  name: "Error404"
};
</script>

<style scoped>

.header {margin:20vh auto 5vh auto; text-align: center;}
.header h1 {color: #4DA0DF;}

.container {
  height: 80vh; width: 100%;
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-flow: column;
}

.center img { width: 50vh;}
.logo {width: 12vh; margin:2vh auto 3vh auto; }
.text {width: 50vh; margin:2vh auto 1vh auto; text-align: center; text-decoration: underline; }
</style>


